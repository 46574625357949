import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <h1>PAGE NOT FOUND</h1>
    <p>Sorry we can't find that page</p>

    <p>
      <Link to="/">Return to Home page</Link>
    </p>
  </Layout>
)

export default NotFoundPage
